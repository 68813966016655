import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import styled from "styled-components";

import ResearchListPage from "./components/page/ResearchListPage";

import Loading from "components/atoms/loading/Loading";
import Toast from "components/atoms/toast/Toast";

import ErrorPage from "components/page/ErrorPage";
import LandingPage from "components/page/LandingPage";
import LoginPage from "components/page/LoginPage";
import PasswordResetPage from "components/page/PasswordResetPage";
import PlanPage from "components/page/PlanPage";
import PrivacyPolicyPage from "components/page/PrivacyPolicyPage";
import ReferenceDetailPage from "components/page/ReferenceDetailPage";
import ReferenceListPage from "components/page/ReferenceListPage";
import ReferenceReviewPage from "components/page/ReferenceReviewPage";
import ResearchDetailPage from "components/page/ResearchDetailPage";
import SearchPage from "components/page/SearchPage";
import SettingsPage from "components/page/SettingsPage";
import SignUpPage from "components/page/SignUpPage";
import TermsOfServicePage from "components/page/TermsOfServicePage";
import WelcomePopup from "components/page/popup/WelcomePopup";
import UxPage from "components/page/ux/UxListPage";
import UxColorPage from "components/page/ux/components/UxColorPage";
import UxIconPage from "components/page/ux/components/UxIconPage";

import GoogleAnalyticsService from "services/GoogleAnalyticsService";

import "App.scss";

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: inherit;
    ${(props) =>
        props.review &&
        `
    overflow: hidden;
    `}
`;

const Row = styled.div`
    display: flex;
    align-items: flex-start;
`;

function App() {
    const location = useLocation();
    const pathname = location.pathname;

    GoogleAnalyticsService();

    return (
        <div className="App">
            <Loading>
                <Toast />
                <Container review={pathname.includes("/review")}>
                    <Row>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />

                            <Route exact path={"/plan"} element={<PlanPage />} />
                            <Route exact path={"/privacy-policy"} element={<PrivacyPolicyPage />} />
                            <Route exact path={"/terms-of-service"} element={<TermsOfServicePage />} />

                            <Route exact path={"/login"} element={<LoginPage />} />
                            <Route exact path={"/signup"} element={<SignUpPage />} />
                            <Route exact path={"/reset-password"} element={<PasswordResetPage />} />
                            <Route exact path={"/settings"} element={<SettingsPage />} />

                            <Route exact path={"/search"} element={<SearchPage />} />
                            <Route exact path={"/reference"} element={<ReferenceListPage />} />
                            <Route exact path={"/reference/:uuid"} element={<ReferenceDetailPage />} />
                            <Route
                                exact
                                path={"/reference/:reference_uuid/review/:reference_file_uuid"}
                                element={<ReferenceReviewPage />}
                            />
                            <Route exact path={"/research"} element={<ResearchListPage />} />
                            <Route exact path={"/research/:uuid"} element={<ResearchDetailPage />} />
                            <Route exact path={"/error"} element={<ErrorPage />} />

                            {/* popup */}
                            <Route exact path={"/popup/welcome"} element={<WelcomePopup />} />

                            {/* ux component */}
                            {process.env.REACT_APP_ENV !== "prd" && (
                                <>
                                    <Route exact path={"/ux/component"} element={<UxPage />} />
                                    <Route exact path={"/ux/component/color"} element={<UxColorPage />} />
                                    <Route exact path={"/ux/component/icon"} element={<UxIconPage />} />
                                </>
                            )}
                        </Routes>
                    </Row>
                </Container>
            </Loading>
        </div>
    );
}

export default App;

import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import AiCursor from "components/atoms/AiCursor";
import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import useToast from "components/atoms/toast/useToast";

import { useSelectBoxOptions } from "hooks/queries/useOptions";
import { useUser } from "hooks/queries/useUser";

import { getJsonToBib } from "utils/request/bibliography";
import { apiUrl, wsUrl } from "utils/urls";
import { uuidv4 } from "utils/uuid";

import axios from "axios";
import saveAs from "file-saver";

const Container = styled.div`
    width: 100%;
    max-height: calc(100% - 24px);
`;

const ContentsWrap = styled.div`
    display: flex;
    margin: 24px 0 0 0;
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);
`;

const ContentsSection = styled.div`
    flex: 1;
`;

const ItemView = styled.div`
    width: 100%;
    height: 100%;
`;

const TabHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
`;

const TabSection = styled.div`
    display: flex;
    align-items: center;
`;

const TabButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background-color: var(--color-White);
    border: Transparent;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(props) => (props.active ? "var(--color-Key)" : "var(--color-DisabledInput)")};
    }
`;

const TabViewSection = styled.div`
    margin: 16px 0 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 100px);
    overflow: auto;
`;

const RelResearchView = styled.div`
    position: relative;
    width: 100%;
    overflow-y: hidden;
    height: calc(100% - 40px);
`;

const RelResearchViewFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-top: solid 1px var(--color-Line);
`;

const RelParagraphWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const RelParagraph = styled.div`
    margin: 0;
    padding: 4px;
    width: 100%;
    height: calc(100% - 8px);
    border: transparent;
    border-radius: 4px;
    background-color: var(--color-White);
    text-align: justify;
    line-height: 1.4;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    resize: none;
    white-space: pre-wrap;

    &:focus {
        outline: none;
        background-color: var(--color-Base1);
    }

    &:disabled {
        cursor: text;
    }
`;

const ReferenceView = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const ButtonSection = styled.div`
    display: flex;
    align-items: center;
`;

const ReferenceListWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
`;

const RefListItemWrap = styled.div`
    padding: 8px 0;
    width: 100%;
    border-bottom: dashed 1px var(--color-Line);
`;

const AnalysisView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StopButtonWrap = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
`;

function ResearchAnalysisModal(props) {
    const [activeTab, setActiveTab] = useState("related");
    const [analysis, setAnalysis] = useState("");
    const [analyzing, setAnalyzing] = useState(false);

    const [language, setLanguage] = useState("");
    const [languageOptions, setLanguageOptions] = useState([]);

    const [style, setStyle] = useState("");
    const [styleList, setStyleList] = useState([]);
    const [styledTexts, setStyledTexts] = useState([]);

    const userQuery = useUser();
    const optionsQuery = useSelectBoxOptions();

    const { setToast } = useToast();
    const { setLoading } = useLoading();

    const wsRef = useRef(null);

    useEffect(() => {
        if (userQuery.data) {
            setLanguage(userQuery.data.env_ai_language);
        }
        if (optionsQuery.data) {
            setLanguageOptions(optionsQuery.data.env_ai_language);
            setStyleList(optionsQuery.data.env_citation_style);
        }
    }, [userQuery.data, optionsQuery.data]);

    useEffect(() => {
        if (props.research.citation_style) {
            setStyle(props.research.citation_style);
        }
    }, [props.research.citation_style]);

    useEffect(() => {
        if (props.research.ai_response_language) {
            setLanguage(props.research.ai_response_language);
        }
    }, [props.research.ai_response_language]);

    useEffect(() => {
        if (props.references && style) {
            handleChangeStyle(style);
        }
    }, [props.references, style]);

    const createAnalysis = () => {
        setLoading(true);
        wsRef.current = new WebSocket(wsUrl("researchAnalysisConsumer"));

        wsRef.current.onerror = (error) => {
            console.log(error);
        };

        wsRef.current.onopen = () => {
            setAnalyzing(true);
            wsRef.current.send(
                JSON.stringify({
                    action: "analyze",
                    language: language,
                    research_id: props.research.id,
                    reference_uuids: props.references.map((reference) => reference.uuid),
                }),
            );
        };

        wsRef.current.onmessage = (evt) => {
            const data = JSON.parse(evt.data);

            // 리서치 설명에 데이터를 더한다.
            if (data.content === "<START>") {
                setLoading(false);
                setActiveTab("related");
            } else if (data.content === "<END>") {
                wsRef.current.close();
            } else {
                // research state의 desc 필드에 data.content(String) 를 더한다.
                setAnalysis((prev) => prev + data.content);
            }
        };

        wsRef.current.onclose = (event) => {
            setLoading(false);
            wsRef.current = null;
            setAnalyzing(false);
            if (event.code !== 1000) {
                // 정상 종료가 아닌 경우
                console.log(event);
                Alert("warn", "에러", "연결이 끊겼습니다.\n잠시 후 새로고침 해주세요.");
            }
        };
    };

    const handleCopyButtonClick = () => {
        navigator.clipboard
            .writeText(styledTexts.join("\n"))
            .then(() => {
                setToast("복사되었습니다.", "info");
            })
            .catch((error) => {
                console.error("복사 실패:", error);
            });
    };

    const handleDownloadBib = () => {
        getJsonToBib(props.references.map((reference) => reference.bib_json)).then((res) => {
            if (res.bibs) {
                saveAs(new Blob([res.bibs.join("\n")]), `paper-${new Date().toISOString()}.bib`);
            } else {
                Alert("warn", "에러", "BibTeX 변환에 실패했습니다.");
            }
        });
    };

    const handleChangeStyle = (style) => {
        setLoading(true);
        axios
            .post(apiUrl("paperStyle"), {
                style_type: style,
                reference_ids: props.references.map((reference) => reference.id),
            })
            .then((res) => {
                setLoading(false);
                setStyle(style);
                setStyledTexts(res.data);
            })
            .catch((err) => {
                setLoading(false);
                Alert("error", "에러", "스타일 변경에 실패했습니다.");
            });
        setLoading(false);
    };

    const handleAddAnalysisToEditor = () => {
        setLoading(true);
        const quill = props.editorRef.current.getEditor();
        quill.focus();
        const range = quill.getSelection();
        const plainText = "\n" + analysis;

        const referencePattern = /\[(\d+)\]/g;
        let match;
        let references = [];

        while ((match = referencePattern.exec(plainText)) !== null) {
            const refId = match[1];
            const uuid = uuidv4(); // front_uuid로 사용할 UUID 생성

            references.push({
                uuid: props.references[parseInt(refId, 10) - 1]?.uuid,
                front_uuid: uuid,
                match: match[0], // 나중에 일치한 부분을 고유하게 식별하기 위해 사용
                index: match.index, // 일치한 인용문의 위치를 기억
            });
        }

        if (references.length > 0) {
            props.referenceCitationMutation.mutate(
                {
                    research_id: props.research.id,
                    references: references,
                },
                {
                    onSuccess: (response) => {
                        // 줄바꿈을 <br> 태그로 변환하여 HTML 내용 생성
                        let htmlContent = plainText
                            .split("\n")
                            .map((line) => {
                                let lineWithSpans = line;

                                // 라인 내의 인용문 패턴을 찾아 <span>으로 변환
                                references.forEach((ref) => {
                                    const citation = response.citations.find(
                                        (cit) => cit.reference_front_uuid === ref.front_uuid,
                                    );
                                    const text = response.research.cited_references?.find(
                                        (reference) => reference.uuid === ref.uuid,
                                    )?.citation_phrase;

                                    if (citation) {
                                        const spanTag = `<span class="ql-editor-reference-blot" data-reference="${ref.uuid}" data-id="${citation.id}" data-front-uuid="${ref.front_uuid}">${text}</span>`;
                                        lineWithSpans = lineWithSpans.replace(ref.match, spanTag);
                                    }
                                });

                                return lineWithSpans;
                            })
                            .join("<br/>");

                        // 생성된 HTML 내용을 Quill 에디터에 삽입
                        quill.clipboard.dangerouslyPasteHTML(range.index, htmlContent, "api");

                        // 업데이트된 내용을 저장
                        props.updateResearchMutation.mutate({
                            id: props.research.id,
                            html_content: quill.root.innerHTML,
                        });
                        props.exitModal();
                        setLoading(false);
                    },
                },
            );
        } else {
            setLoading(false);
        }
    };

    return (
        <Container>
            <ContentsWrap>
                <ContentsSection>
                    <TabSection>
                        <TabButton onClick={() => setActiveTab("related")} active={activeTab === "related"}>
                            <GeneralText
                                size={"regular"}
                                color={activeTab === "related" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                            >
                                관련연구
                            </GeneralText>
                        </TabButton>
                        <TabButton onClick={() => setActiveTab("reference")} active={activeTab === "reference"}>
                            <GeneralText
                                size={"regular"}
                                color={activeTab === "reference" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                            >
                                참고문헌
                            </GeneralText>
                        </TabButton>
                    </TabSection>
                    {activeTab === "related" ? (
                        analysis ? (
                            <ItemView key={"related-view"} active={true}>
                                <TabViewSection>
                                    <RelResearchView>
                                        <RelParagraphWrap>
                                            <RelParagraph>
                                                {analysis}
                                                {analyzing && <AiCursor />}
                                            </RelParagraph>
                                        </RelParagraphWrap>
                                        {analyzing && (
                                            <StopButtonWrap>
                                                <Button
                                                    width={"120px"}
                                                    height={"32px"}
                                                    bgColor={"var(--color-Button2)"}
                                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                                    fontColor={"var(--color-White)"}
                                                    buttonText={"작성 중지"}
                                                    onClick={() => {
                                                        wsRef.current.send(JSON.stringify({ action: "stop" }));
                                                    }}
                                                >
                                                    <Icon name={"stop"} color={"var(--color-White)"} />
                                                </Button>
                                            </StopButtonWrap>
                                        )}
                                    </RelResearchView>
                                    <RelResearchViewFooter>
                                        <Button
                                            onlyText
                                            margin={"8px"}
                                            height={"32px"}
                                            width={"80px"}
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            fontColor={"var(--color-White)"}
                                            buttonText={"추가하기"}
                                            disabled={analyzing}
                                            onClick={() => {
                                                handleAddAnalysisToEditor();
                                            }}
                                        />
                                        <Button
                                            onlyText
                                            margin={"8px"}
                                            height={"32px"}
                                            width={"80px"}
                                            bgColor={"var(--color-Button4)"}
                                            hoverBgColor={"var(--color-ButtonHover4)"}
                                            fontColor={"var(--color-Black)"}
                                            buttonText={"버리기"}
                                            onClick={() => {
                                                if (wsRef.current) {
                                                    wsRef.current.send(JSON.stringify({ action: "stop" }));
                                                }
                                                props.exitModal();
                                            }}
                                        />
                                    </RelResearchViewFooter>
                                </TabViewSection>
                            </ItemView>
                        ) : (
                            <AnalysisView>
                                <GeneralText size={"regular"}>AI가 선택된 참고문헌들을 분석하여</GeneralText>
                                <GeneralText size={"regular"}>관련연구와 참고문헌을 작성해드립니다.</GeneralText>
                                <GeneralText size={"regular"} margin={"24px 0 40px; 0"}>
                                    관련연구를 작성할 언어를 선택해주세요.
                                </GeneralText>
                                <ButtonSection>
                                    <SelectBox
                                        width="100px"
                                        height="32px"
                                        placeholder="언어 선택"
                                        items={languageOptions}
                                        value={language}
                                        onChange={(e) => setLanguage(e)}
                                    />
                                    <Button
                                        margin={"0 0 0 8px"}
                                        height={"32px"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        fontColor={"var(--color-White)"}
                                        buttonText={"자동 분석 시작"}
                                        onClick={createAnalysis}
                                        disabled={analyzing}
                                    >
                                        <Icon name={"ai"} size={"12"} color="var(--color-White)" />
                                    </Button>
                                </ButtonSection>
                            </AnalysisView>
                        )
                    ) : (
                        <ItemView>
                            <TabHeader>
                                <ButtonSection>
                                    <GeneralText size={"regular"} margin={"0 8px 0 0"} color={"var(--color-Grey1)"}>
                                        스타일
                                    </GeneralText>
                                    <SelectBox
                                        width={"140px"}
                                        height={"32px"}
                                        placeholder="스타일"
                                        items={styleList}
                                        value={style}
                                        onChange={(e) => handleChangeStyle(e)}
                                    />
                                    <Tooltip message={"복사"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            margin={"0 8px"}
                                            onClick={() => handleCopyButtonClick(styleList)}
                                        >
                                            <Icon name={"copy"} size={"12"} color={"var(--color-White)"} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip message={"다운로드"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            onClick={() => handleDownloadBib()}
                                        >
                                            <Icon name={"download"} size={"12"} color={"var(--color-White)"} />
                                        </Button>
                                    </Tooltip>
                                </ButtonSection>
                            </TabHeader>
                            <TabViewSection>
                                <ReferenceView>
                                    <ReferenceListWrap>
                                        {styledTexts.map((styledText, index) => (
                                            <RefListItemWrap key={index}>
                                                <GeneralText size={"regular"} color={"var(--color-Grey1)"}>
                                                    {styledText}
                                                </GeneralText>
                                            </RefListItemWrap>
                                        ))}
                                    </ReferenceListWrap>
                                </ReferenceView>
                            </TabViewSection>
                        </ItemView>
                    )}
                </ContentsSection>
            </ContentsWrap>
        </Container>
    );
}

export default ResearchAnalysisModal;

import { apiUrl } from "utils/urls";

import axios from "axios";

export const getPaperSearch = async (params) => {
    const { data } = await axios.get(apiUrl("paperSearch"), { params: params });
    return data;
};

export const getPaperSearchHistory = async (params) => {
    const { data } = await axios.get(apiUrl("paperSearchHistory"), {
        params: params,
    });
    return data;
};

export const deletePaperSearchHistory = async (body) => {
    const { data } = await axios.delete(apiUrl("paperSearchHistory"), {
        data: body,
    });
    return data;
};

export const getPaperRecommendation = async (params) => {
    const { data } = await axios.get(apiUrl("paperRecommendation"), {
        params: params,
    });
    return data;
};

export const getPaperMetadata = (params) => {
    return axios.get(apiUrl("paperMetadata"), { params: params });
};

export const getTags = async (params) => {
    const { data } = await axios.get(apiUrl("tag"), { params: params });

    data.sort((a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
    });
    return data;
};

export const postTag = async (body) => {
    const { data } = await axios.post(apiUrl("tag"), body);
    return data;
};

export const putTag = async (body) => {
    const { data } = await axios.put(apiUrl("tag") + `/${body.id}`, body);
    return data;
};
export const deleteTag = async (body) => {
    const { data } = await axios.delete(apiUrl("tag") + `/${body.id}`);
    return data;
};

export const deleteBulkTag = async (body) => {
    const { data } = await axios.delete(apiUrl("tag") + `/bulk-delete`, { data: body });
    return data;
};

export const addTagReference = async (body) => {
    const { data } = await axios.post(apiUrl("tag") + `/reference`, body);
    return data;
};

export const changeTagOrder = async (body) => {
    const { data } = await axios.post(apiUrl("tag") + `/order`, body);
    return data;
};

export const getTagRecommendation = async (body) => {
    const { data } = await axios.post(apiUrl("tag") + `/recommend`, body);
    return data;
};

import React from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Button from "components/atoms/button/Button";

const SidebarWrapper = styled.div`
    position: fixed;
    z-index: 100;
    right: ${(props) => props.right};
    top: 90px;
    height: calc(100% - 90px);
    width: 540px;
    background-color: var(--color-White);
    border-left: solid 1px var(--color-Outline);
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.05);
    transition: all 360ms ease-in-out;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const SidebarContent = styled.div`
    margin: 0 0 0 4px;
    flex-grow: 1;
    overflow-y: auto;
`;

const RightSidebar = (props) => {
    return (
        <SidebarWrapper right={props.right}>
            <SidebarContent>{props.children}</SidebarContent>
        </SidebarWrapper>
    );
};

export default RightSidebar;

import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

const BodyContentListView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ListViewTableWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const ListViewTable = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
`;

const TableHead = styled.thead`
    width: 100%;
`;

const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const TableHeadTr = styled.tr`
    th {
        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
`;

const TableHeadTh = styled.th`
    position: relative;
    height: 32px;
    border-bottom: solid 1px var(--color-Outline);

    &:last-child {
        border-right: none;
    }

    .sortHandle,
    .resizeHandle {
        visibility: hidden;
    }

    .sort-active {
        visibility: visible;
    }

    &:hover {
        .sortHandle,
        .resizeHandle {
            visibility: visible;
        }
    }
`;

const TableHeadThTitle = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${(props) => props.cursor};
    padding-left: 4px;
`;

const TableBody = styled.tbody`
    > tr {
        height: 48px;
        border-bottom: solid 1px var(--color-Outline);
        cursor: default;

        &:hover {
            transition: all 80ms ease-in;
            background-color: #e9f2f4;
        }
    }
`;

const TableCell = styled.td`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const TableCellContentWrap = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 4px;
`;

const ListViewPagination = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    padding: 10px 0;
`;

const ListViewPageButtonWrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const ListViewPageSelectBoxWrap = styled.div`
    margin-left: auto;
`;

const TableHeadThSortHandle = styled.div`
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;

    &:hover {
        background-color: #aaccc6;
    }
`;

const ResizeHandle = styled.div`
    position: absolute;
    top: 6px;
    right: 0;
    width: 4px;
    height: 24px;
    background-color: var(--color-White);
    cursor: col-resize;
    user-select: none;
`;

const RefTableHeader = ({ header }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
        id: header.column.id,
    });

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        backgroundColor: "var(--color-Base2)",
        transform: CSS.Translate.toString(transform),
        transition: "width transform 0.2s ease-in-out",
        whiteSpace: "nowrap",
        textAlign: "left",
        zIndex: isDragging ? 1 : 0,
        width: header.column.getSize(),
    };

    return (
        <TableHeadTh key={header.id} colSpan={header.colSpan} ref={setNodeRef} style={style}>
            {!header.isPlaceholder && (
                <TableHeadThTitle>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {!["id", "is_bookmarked"].includes(header.column.id) && (
                        <TableHeadThSortHandle
                            className={`sortHandle ${
                                header.column.getIsSorted() &&
                                !["id", "is_bookmarked"].includes(header.column.id) &&
                                "sort-active"
                            }`}
                            onMouseDown={
                                ["id", "is_bookmarked"].includes(header.column.id)
                                    ? undefined
                                    : header.column.getToggleSortingHandler()
                            }
                            title={
                                header.column.getCanSort() && !["id", "is_bookmarked"].includes(header.column.id)
                                    ? header.column.getNextSortingOrder() === "asc"
                                        ? "오름차순으로 정렬"
                                        : header.column.getNextSortingOrder() === "desc"
                                        ? "내림차순으로 정렬"
                                        : "정렬 해제"
                                    : undefined
                            }
                        >
                            {{
                                asc: "↑",
                                desc: "↓",
                            }[header.column.getIsSorted()] ?? "↕"}
                        </TableHeadThSortHandle>
                    )}
                </TableHeadThTitle>
            )}
            {!["id", "is_bookmarked"].includes(header.column.id) && (
                <ResizeHandle
                    className={"resizeHandle"}
                    {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                    }}
                >
                    &nbsp;
                </ResizeHandle>
            )}
        </TableHeadTh>
    );
};

function Table(props) {
    // props
    // columns: column data(header)
    // data: row data
    // pagination: {pageIndex, pageSize}
    // sortable: true, false
    //      sorting: {field:'display_name' ,desc:false}

    const { tableKey, data, pagination, setPagination, sorting, setSorting } = props;
    const tableRef = React.useRef();
    const getColumnHeaderName = (info) => {
        return (
            <GeneralText size={"small"} fontWeight={"500"} color={"#2F5B51"}>
                {info.column.columnDef.name}
            </GeneralText>
        );
    };

    let columns = props.columns;

    columns = columns.map((column) => {
        if (column.header === undefined) {
            column.header = getColumnHeaderName;
        }
        return column;
    });

    const table = useReactTable({
        data: data,
        columns: columns,
        ...(pagination && {
            manualPagination: true,
            pageCount: Math.ceil(pagination.count / pagination.pageSize),
            initialState: {
                pagination: {
                    pageIndex: pagination.pageIndex - 1,
                    pageSize: pagination.pageSize,
                },
            },
            getPaginationRowModel: getPaginationRowModel(),
            onPaginationChange: (updater) => {
                const updatedPagination =
                    typeof updater === "function" ? updater(table.getState().pagination) : updater;

                setPagination({
                    pageIndex: updatedPagination.pageIndex + 1,
                    pageSize: updatedPagination.pageSize,
                });
            },
        }),
        getCoreRowModel: getCoreRowModel(),
        ...(sorting && {
            state: {
                sorting: sorting,
            },
            manualSorting: true,
        }),
        ...(setSorting && {
            onSortingChange: (updater) => {
                const updatedSorting = typeof updater === "function" ? updater(table.getState().sorting) : updater;
                setSorting(updatedSorting);
            },
        }),
        getSortedRowModel: sorting ? getSortedRowModel() : undefined,
        columnResizeMode: "onChange",
        columnResizeDirection: "ltr",
        defaultColumn: {
            minSize: 100,
        },
    });

    useEffect(() => {
        if (pagination) {
            table.setState((old) => {
                return {
                    ...old,
                    pagination: {
                        pageIndex: pagination.pageIndex - 1,
                        pageSize: pagination.pageSize,
                    },
                };
            });
        }
    }, [pagination]);

    useEffect(() => {
        if (sorting) {
            table.setState((old) => {
                return {
                    ...old,
                    sorting: sorting,
                };
            });
        } else {
            table.setState((old) => {
                return {
                    ...old,
                    sorting: [],
                };
            });
        }
    }, [sorting]);

    useEffect(() => {
        const tableWidth = localStorage.getItem(`columnSizing-${tableKey}`);
        const fixedColumn = ["id", "is_bookmarked"];
        if (tableWidth) {
            const savedSizing = JSON.parse(tableWidth);

            columns.forEach((column) => {
                if (savedSizing[column.id]) {
                    column.size = savedSizing[column.id];
                } else {
                    column.size = fixedColumn.includes(column.id)
                        ? 45
                        : (tableRef.current.offsetWidth - fixedColumn.length * 45) /
                          (columns.length - fixedColumn.length);
                }
            });
        } else {
            const totalWidth = tableRef.current.offsetWidth;

            const columnWidth = (totalWidth - fixedColumn.length * 45) / (columns.length - fixedColumn.length);

            const columnSizing = {};

            columns.forEach((column) => {
                if (!fixedColumn.includes(column.id)) {
                    column.size = columnWidth;
                    columnSizing[column.id] = columnWidth;
                } else {
                    column.size = 45;
                    columnSizing[column.id] = 45;
                }
            });
            localStorage.setItem(`columnSizing-${tableKey}`, JSON.stringify(columnSizing));
        }
    }, [columns, tableKey]);

    useEffect(() => {
        const currentSizing = table.getState().columnSizing;

        const existingSizing = localStorage.getItem(`columnSizing-${tableKey}`);
        let mergedSizing = {};

        if (existingSizing) {
            mergedSizing = JSON.parse(existingSizing);
        }

        mergedSizing = {
            ...mergedSizing,
            ...currentSizing,
        };

        localStorage.setItem(`columnSizing-${tableKey}`, JSON.stringify(mergedSizing));
    }, [table.getState().columnSizing, tableKey]);

    return (
        <BodyContent>
            <BodyContentListView ref={tableRef}>
                <ListViewTableWrap>
                    <ListViewTable
                        style={{
                            width: table.getTotalSize(),
                        }}
                    >
                        <TableHead>
                            {props.sortable
                                ? table.getHeaderGroups().map((headerGroup) => (
                                      <TableHeadTr key={headerGroup.id}>
                                          {headerGroup.headers.map((header) => (
                                              <RefTableHeader key={header.id} header={header} />
                                          ))}
                                      </TableHeadTr>
                                  ))
                                : table.getHeaderGroups().map((headerGroup) => (
                                      <TableHeadTr key={headerGroup.id}>
                                          {headerGroup.headers.map((header) => (
                                              <TableHeadTh
                                                  key={header.id}
                                                  colSpan={header.colSpan}
                                                  style={{
                                                      padding: "8px 6px",
                                                      backgroundColor: "var(--color-Base2)",
                                                      transition: "width transform 0.2s ease-in-out",
                                                      whiteSpace: "nowrap",
                                                      textAlign: "center",
                                                      width: header.column.getSize(),
                                                  }}
                                              >
                                                  <TableHeadThTitle>
                                                      {flexRender(header.column.columnDef.header, header.getContext())}
                                                  </TableHeadThTitle>
                                              </TableHeadTh>
                                          ))}
                                      </TableHeadTr>
                                  ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            style={{
                                                padding: "8px 6px",
                                                height: "48px",
                                                transition: "width transform 0.2s ease-in-out",
                                                maxWidth: `${cell.column.getSize()}px`,
                                            }}
                                        >
                                            <TableCellContentWrap style={cell.column.columnDef.style}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </TableCellContentWrap>
                                        </TableCell>
                                    ))}
                                </tr>
                            ))}
                        </TableBody>
                    </ListViewTable>
                </ListViewTableWrap>
                {pagination && (
                    <ListViewPagination>
                        <ListViewPageButtonWrap>
                            <Button
                                onlyIcon
                                transparentDisabled
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => table.firstPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <Icon
                                    name={"arrowLeftDouble"}
                                    color={
                                        !table.getCanPreviousPage()
                                            ? "var(--color-DisabledText)"
                                            : "var(--color-SubBlack)"
                                    }
                                />
                            </Button>
                            <Button
                                onlyIcon
                                transparentDisabled
                                margin={"0 4px"}
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <Icon
                                    name={"arrowLeft"}
                                    color={
                                        !table.getCanPreviousPage()
                                            ? "var(--color-DisabledText)"
                                            : "var(--color-SubBlack)"
                                    }
                                />
                            </Button>
                            {Array.from({ length: table.getPageCount() }, (_, index) => (
                                <Button
                                    onlyText
                                    margin={"0 4px"}
                                    width={"24px"}
                                    height={"24px"}
                                    key={index}
                                    onClick={() => table.setPageIndex(index)}
                                    bgColor={
                                        table.getState().pagination.pageIndex === index
                                            ? "var(--color-Key)"
                                            : "transparent"
                                    }
                                    hoverBgColor={
                                        table.getState().pagination.pageIndex === index
                                            ? "var(--color-DarkKey)"
                                            : "var(--color-ButtonHover4)"
                                    }
                                    buttonText={index + 1}
                                    fontColor={
                                        table.getState().pagination.pageIndex === index
                                            ? "var(--color-White)"
                                            : "var(--color-SubBlack)"
                                    }
                                />
                            ))}
                            <Button
                                onlyIcon
                                transparentDisabled
                                margin={"0 4px"}
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                <Icon
                                    name={"arrowRight"}
                                    color={
                                        !table.getCanNextPage() ? "var(--color-DisabledText)" : "var(--color-SubBlack)"
                                    }
                                />
                            </Button>
                            <Button
                                onlyIcon
                                transparentDisabled
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => table.lastPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                <Icon
                                    name={"arrowRightDouble"}
                                    color={
                                        !table.getCanNextPage() ? "var(--color-DisabledText)" : "var(--color-SubBlack)"
                                    }
                                />
                            </Button>
                            {/* <span>
                                                <div>Page</div>
                                                <strong>
                                                    {table.getState().pagination.pageIndex + 1} of{" "}
                                                    {table.getPageCount().toLocaleString()}
                                                </strong>
                                            </span>
                                            <span>
                                                | Go to page:
                                                <input
                                                    type="number"
                                                    defaultValue={table.getState().pagination.pageIndex + 1}
                                                    onChange={(e) => {
                                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                        table.setPageIndex(page);
                                                    }}
                                                />
                                            </span> */}
                        </ListViewPageButtonWrap>
                        <ListViewPageSelectBoxWrap>
                            <SelectBox
                                width={"120px"}
                                optionWidth={"100%"}
                                topBottom={"bottom"}
                                reverse={"180deg"}
                                value={String(table.getState().pagination.pageSize)}
                                items={[
                                    { id: "10", name: "10개씩 보기" },
                                    { id: "20", name: "20개씩 보기" },
                                    { id: "30", name: "30개씩 보기" },
                                    { id: "40", name: "40개씩 보기" },
                                    { id: "50", name: "50개씩 보기" },
                                ]}
                                onChange={(id) => {
                                    table.setPageSize(Number(id));
                                }}
                            />
                        </ListViewPageSelectBoxWrap>
                    </ListViewPagination>
                )}
            </BodyContentListView>
        </BodyContent>
    );
}

export default Table;

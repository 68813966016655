import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import GeneralText from "components/atoms/text/GeneralText";

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${(props) => props.margin};
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth};
    height: 32px;
    background-color: ${(props) => (props.disabled ? "#F2F6F8" : "var(--color-White)")};
    border: ${(props) => (props.isOpen ? "solid 1px var(--color-Key)" : "solid 1px var(--color-Outline)")};
    border-radius: 4px;
`;

const SelectedItem = styled.div`
    display: flex;
    align-items: center;
    margin: 0 4px 0 0;
    padding: 0 0 0 12px;
    width: calc(100% - 24px);
    max-width: calc(100% - 24px);
    height: 100%;
    word-break: break-all;
`;

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;
    width: 10px;
    height: 10px;
    transform: rotate(${(props) => props.reverse || "0deg"});
`;

const OptionContainer = styled.div`
    position: absolute;
    ${(props) => props.topBottom || "top"}: 36px;
    right: 0;
    width: ${(props) => props.optionWidth || "100%"};
    min-width: ${(props) => props.optionMinWidth};
    height: ${(props) => (props.isOpen ? "auto" : "0")};
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
    visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
    overflow: hidden;
    transition: all 100ms ease-in;
    z-index: 100;
`;

const OptionWrapper = styled.div`
    width: 100%;
    padding: 4px 2px 3px 6px;
    background-color: var(--color-White);
    border-radius: 4px;
    border: solid 1px var(--color-Key);
`;

const OptionList = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-height: 120px;
    border-radius: 2px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        display: initial;
    }

    &::-webkit-scrollbar-thumb {
        min-width: 40px;
        min-height: 20px;
        background-color: var(--color-DarkKey);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-ButtonHover4);
    }
`;

const OptionItem = styled.li`
    width: 100%;
    height: 32px;
    list-style: none;
`;

const OptionItemInner = styled.div`
    display: flex;
    align-items: center;
    margin: 1px 4px 1px 0;
    padding: 0 8px;
    width: calc(100% - 4px);
    height: 30px;
    border-radius: 2px;
    border: transparent;
    background-color: var(--color-White);
    transition: all 50ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
        cursor: pointer;
    }
`;

const SelectedOptionItemInner = styled(OptionItemInner)`
    background-color: var(--color-Key) !important;

    p {
        color: var(--color-White);
    }
`;

function CustomSelectBox(props) {
    const selectedOptionName = props.items?.find((item) => item.id === props.value)?.name;

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // 영역 외 영역을 클릭하면 드롭다운 닫힘
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener("mousedown", handleOutsideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <Container
            width={props.width}
            maxWidth={props.maxWidth}
            margin={props.margin}
            disabled={props.disabled}
            onClick={(e) => {
                e.stopPropagation();
                setIsOpen(props.disabled ? false : !isOpen);
            }}
            onChange={props.onChange}
            isOpen={isOpen}
            ref={dropdownRef}
        >
            <SelectedItem>
                <GeneralText ellipsis size={"small"} width={"100%"} textAlign={"center"}>
                    {selectedOptionName}
                </GeneralText>
            </SelectedItem>
            <IconBox reverse={props.reverse}>
                <Icon name={isOpen ? "arrowUp" : "arrowDown"} color={"var(--color-SubBlack)"} />
            </IconBox>
            <OptionContainer
                optionWidth={props.optionWidth}
                optionMinWidth={props.optionMinWidth}
                isOpen={isOpen}
                topBottom={props.topBottom}
            >
                <OptionWrapper>
                    <OptionList>
                        {props.items?.map((item, index) => (
                            <OptionItem
                                key={index}
                                value={item.id}
                                onClick={(e) => {
                                    props.onChange(item.id);
                                    setIsOpen(false);
                                }}
                            >
                                {props.value === item.id ? (
                                    <SelectedOptionItemInner>
                                        <GeneralText ellipsis size={"small"} width={"100%"} textAlign={"center"}>
                                            {item.name || item}
                                        </GeneralText>
                                    </SelectedOptionItemInner>
                                ) : (
                                    <OptionItemInner>
                                        <GeneralText ellipsis size={"small"} width={"100%"} textAlign={"center"}>
                                            {item.name || item}
                                        </GeneralText>
                                    </OptionItemInner>
                                )}
                            </OptionItem>
                        ))}
                    </OptionList>
                </OptionWrapper>
            </OptionContainer>
        </Container>
    );
}

export default CustomSelectBox;

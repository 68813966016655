import React, { useEffect, useRef } from "react";

import styled, { css } from "styled-components";

import Image from "components/atoms/Image";
import GeneralText from "components/atoms/text/GeneralText";

const Container = styled.div`
    position: relative;
    width: ${(props) => props.width || "fit-content"};
    height: fit-content;
    display: inline-block;
`;

const TooltipBox = styled.div`
    position: absolute;
    display: none;
    padding: 4px 6px;
    width: max-content;
    background-color: rgba(11, 33, 44, 0.9);
    border-radius: 4px;
    letter-spacing: -0.25px;
    text-align: center;
    opacity: 1;
    z-index: 100;
    pointer-events: auto;

    ${(props) =>
        props.position === "top" &&
        css`
            top: 0;
            right: 50%;
            transform: translate(50%, -130%);
        `};

    ${(props) =>
        props.position === "right" &&
        css`
            top: 50%;
            right: 0;
            transform: translate(120%, -50%);
        `};

    ${(props) =>
        props.position === "bottom" &&
        css`
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 130%);
        `};

    ${(props) =>
        props.position === "left" &&
        css`
            top: 50%;
            left: 0;
            transform: translate(-120%, -50%);
        `};
`;

const TooltipArrow = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9px;
    height: 6px;

    ${(props) =>
        props.position === "top" &&
        css`
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%) rotate(-180deg);
        `}

    ${(props) =>
        props.position === "right" &&
        css`
            top: 50%;
            left: -7px;
            transform: translateY(-50%) rotate(-90deg);
        `}

    ${(props) =>
        props.position === "bottom" &&
        css`
            top: -6px;
            left: 50%;
            transform: translateX(-50%) rotate(0deg);
        `}

    ${(props) =>
        props.position === "left" &&
        css`
            top: 50%;
            right: -7px;
            transform: translateY(-50%) rotate(90deg);
        `}
`;

function Tooltip(props) {
    const { position = "bottom", width, children, message } = props;
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const tooltip = container.querySelector(".tooltip");

        const showTooltip = () => {
            if (tooltip) {
                tooltip.style.display = "block";
            }
        };

        const hideTooltip = () => {
            if (tooltip) {
                tooltip.style.display = "none";
            }
        };

        const handleMouseMove = (e) => {
            const rect = container.getBoundingClientRect();
            if (e.clientX < rect.left || e.clientX > rect.right || e.clientY < rect.top || e.clientY > rect.bottom) {
                hideTooltip();
            } else {
                showTooltip();
            }
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <Container width={width} ref={containerRef}>
            {children}
            {message && (
                <>
                    <TooltipBox className="tooltip" position={position}>
                        <GeneralText size="small" color="var(--color-White)">
                            {message}
                        </GeneralText>
                        <TooltipArrow position={position}>
                            <Image name={"tooltipArrow"} />
                        </TooltipArrow>
                    </TooltipBox>
                </>
            )}
        </Container>
    );
}

export default Tooltip;

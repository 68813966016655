import { useMutation, useQuery } from "react-query";

import {
    deletePaperSearchHistory,
    getPaperMetadata,
    getPaperRecommendation,
    getPaperSearch,
    getPaperSearchHistory,
} from "utils/request/paper";

export const usePaperSearch = (params) => {
    return useQuery("paperSearch", (e) => getPaperSearch(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaperRecommendation = (params) => {
    return useQuery("paperRecommendation", (e) => getPaperRecommendation(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaperMetadata = (params) => {
    return useQuery("paperMetadata", (e) => getPaperMetadata(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaperSearchHistory = (params) => {
    return useQuery("paperSearchHistory", (e) => getPaperSearchHistory(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useDeletePaperSearchHistory = () => {
    return useMutation(deletePaperSearchHistory);
};

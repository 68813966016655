import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import Icon from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Button from "components/atoms/button/Button";
import TitleText from "components/atoms/text/TitleText";

import ReviewTitleInput from "components/organisms/review/ReviewTitleInput";

import { useDevice } from "utils/device";

const Container = styled.div`
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    min-width: 768px;
    height: 56px;
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Line);
    z-index: 100;

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 16px;
        `};
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
`;

const ConnectStateWrap = styled.div`
    display: flex;
    align-items: center;
`;

const DivideLine = styled.div`
    margin: 0 12px 0 6px;
    height: 20px;
    border-right: solid 1px #e6e6e6;
`;

const TitleSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    max-width: calc(100% - 480px);
`;

function ReviewPageHeader(props) {
    const device = useDevice();

    return (
        <Container device={device}>
            <Link as={Link} to={props.bibliography ? "/reference" : "/"} reloadDocument>
                <Image name={"headerLogo"} />
            </Link>
            <TitleSection>
                <form>
                    <ReviewTitleInput
                        bibliography={props.bibliography}
                        bibliographyMutation={props.bibliographyMutation}
                    />
                </form>
            </TitleSection>
            <RightSection>
                <ConnectStateWrap>
                    <TitleText
                        size={"subSmall"}
                        margin={"0 8px 0 0"}
                        fontWeight={"regular"}
                        color={"var(--color-Grey2)"}
                        textAlign={"center"}
                    >
                        AI서버
                        <br />
                        연결상태
                    </TitleText>
                    <Icon name={props.wsConnected ? "connectOn" : "connectOff"} size={"32px"} />
                </ConnectStateWrap>
                <DivideLine />
                <Button
                    width={"100px"}
                    height={"32px"}
                    bgColor={"var(--color-Button2)"}
                    hoverBgColor={"var(--color-ButtonHover2)"}
                    fontColor={"var(--color-White)"}
                    buttonText="리뷰 종료"
                    onClick={props.onExitClick}
                >
                    <Icon name={"logout"} size={"16"} color={"var(--color-White)"} />
                </Button>
            </RightSection>
        </Container>
    );
}

export default ReviewPageHeader;

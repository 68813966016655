import React, { useEffect, useRef, useState } from "react";
import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment } from "react-complex-tree";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import ContextMenu from "components/molecules/ContextMenu";

import {
    useTagBulkDelete,
    useTagChangeOrder,
    useTagCreate,
    useTagUpdate,
    useTags,
} from "hooks/queries/useBibliographies";

import Constants from "utils/constants";

const RefTagWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const RefTagHeader = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 8px;
    padding: 0 0 4px 0;
    width: calc(100% - 8px);
    height: 24px;
`;

const RefTagBtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 128px;
    height: 24px;
`;

const TreeItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${(props) => (props.depth < 1 ? "0px" : "24px")};
`;

const TreeItemMain = styled.div`
    display: flex;
    align-items: center;
    margin: 1px 0;
    padding: 0 2px;
    width: 100%;
    border-radius: 4px;
    background-color: ${(props) => props.isDraggingOver && "var(--color-Button3)"};

    &:hover {
        background-color: var(--color-Button4);
    }
`;

const TreeItemArrow = styled.div`
    width: 20px;
    height: 20px;

    &:hover {
        cursor: pointer;
    }
`;

const BlankBox = styled.div`
    width: 20px;
    height: 20px;
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 10px;
    height: 24px;
`;

const TreeItemTitle = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0 0 0 4px;

    &:hover {
        cursor: ${(props) => (props.treeId === Constants.TAG_TREE_ID_SIDEMENU ? "pointer" : "default")};
    }
`;

const TreeItemKnob = styled.div`
    width: 20px;
    height: 20px;

    &:hover {
        cursor: pointer;
    }
`;

const TreeItemChildren = styled.div`
    width: 100%;
`;

const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const RefTagBody = styled.div`
    overflow-y: auto;
`;

var isTreeItemDragging = false;

function ReferenceTagTreeView(props) {
    // props:
    // viewState
    // onSelectItems
    // treeId
    const [tags, setTags] = useState({
        root: {
            index: "root",
            name: "root",
        },
    });

    const tagQuery = useTags();
    const tagUpdate = useTagUpdate();
    const tagCreate = useTagCreate();
    const tagDelete = useTagBulkDelete();
    const tagChangeOrder = useTagChangeOrder();

    const { setToast } = useToast();

    const tree = useRef(null);

    // 각 부모의 자식 노드를 재귀적으로 정렬하는 함수
    const buildTreeWithOrdering = (items, parentId) => {
        const children = Object.values(items)
            .filter((item) => item.parent === parentId)
            .sort((a, b) => a.order - b.order);

        // 자식이 있으면 재귀적으로 하위 노드를 정렬
        children.forEach((child) => {
            child.children = buildTreeWithOrdering(items, child.index).map((childItem) => childItem.index);
        });
        return children;
    };

    useEffect(() => {
        if (tagQuery.data) {
            const items = {};
            // 아이템을 `index`를 키로 하여 맵에 저장
            tagQuery.data.forEach((tag) => {
                items[tag.id] = {
                    index: tag.id,
                    name: tag.name,
                    children: tag.children,
                    order: tag.order,
                    parent: tag.parent,
                    isFolder: tag.children.length > 0,
                    citation_count: tag.citation_count,
                };
            });

            // 재귀적으로 트리를 정렬
            const rootChildren = buildTreeWithOrdering(items, null).map((item) => item.index);

            // `root` 노드에 최상위 레벨의 자식 노드를 추가
            items["root"] = {
                index: "root",
                name: "root",
                children: rootChildren,
            };

            setTags(items);
        }
    }, [tagQuery.data]);

    useEffect(() => {
        const allNodeIds = Object.keys(tags);
        dataProvider.onDidChangeTreeDataEmitter.emit(allNodeIds);
    }, [tags]);

    const dataProvider = new StaticTreeDataProvider(tags, (item, name) => ({
        ...item,
        name,
    }));

    dataProvider.onChangeItemChildren = function (itemId, newChildren) {
        const item = this.data.items[itemId];
        item.children = newChildren;
        item.isFolder = newChildren.length > 0;
        this.onDidChangeTreeDataEmitter.emit([itemId]);
    };

    const injectItem = () => {
        // 새로운 아이템을 추가
        // 만약 NewItem이 이미 있다면 순차적으로 1,2,3...으로 이름을 변경
        const existingNames = Object.values(tags).map((item) => item.name);
        let newName = "NewItem";
        let i = 1;
        while (existingNames.includes(newName)) {
            newName = `NewItem${i}`;
            i++;
        }

        tagCreate.mutate(
            {
                name: newName,
            },
            {
                onSuccess: (data, variables, context) => {
                    setToast("success", "태그 추가", "태그가 추가되었습니다.");
                },
            },
        );
    };

    const removeItem = (itemIds = undefined) => {
        if (!itemIds) {
            itemIds = props.viewState[props.treeId].selectedItems;
        }

        const deleteItems = new Set(); // 중복을 제거하기 위해 Set 사용

        const addToDeleteItems = (itemId) => {
            if (!deleteItems.has(itemId)) {
                deleteItems.add(itemId);
                const children = tags[itemId].children || [];
                children.forEach(addToDeleteItems);
            }
        };

        itemIds.forEach(addToDeleteItems);

        Confirm("warn", "태그 삭제", "태그를 삭제하시겠습니까? 하위 태그들도 함께 삭제됩니다.", "삭제", () => {
            tagDelete.mutate(
                {
                    ids: Array.from(deleteItems),
                },
                {
                    onSuccess: (data, variables, context) => {
                        setToast("success", "태그 삭제", "태그가 삭제되었습니다.");
                        props.onSelectItems([]);
                    },
                    onError: (error) => {
                        setToast("error", "태그 삭제", "태그 삭제에 실패했습니다.");
                    },
                },
            );
        });
    };

    const [showContextMenu, setShowContextMenu] = useState(false);

    const handleDrop = () => {
        isTreeItemDragging = false;

        const assignLinearIndex = (currentItems, rootItem) => {
            let index = 0;
            const linearIndices = {};

            const traverseTree = (itemId) => {
                linearIndices[itemId] = index++;
                const children = currentItems[itemId]?.children ?? [];
                children.forEach(traverseTree);
            };

            traverseTree(rootItem);
            return linearIndices;
        };

        // 전체 트리 상태 가져오기
        const currentItems = dataProvider.data.items;
        const rootItem = "root"; // 트리의 루트 아이템

        // 부모 정보를 식별하기 위한 맵 구성
        const parentMap = {};
        Object.keys(currentItems).forEach((itemId) => {
            const item = currentItems[itemId];
            const children = item.children ?? [];
            children.forEach((childId) => {
                parentMap[childId] = itemId; // 자식 아이템 ID를 키로 사용하여 부모 ID 매핑
            });
        });

        // 트리를 순회하며 모든 노드에 대한 `linearIndex`를 계산
        const allLinearIndices = assignLinearIndex(currentItems, rootItem);

        // 모든 노드에 대한 부모 및 순서 정보 수집
        const updatedNodes = Object.keys(currentItems)
            .filter((itemId) => itemId !== rootItem) // `root`를 제외
            .map((itemId) => {
                const parent = parentMap[itemId] || null; // 부모가 없으면 null
                return {
                    id: itemId,
                    parent: parent === rootItem ? null : parent,
                    order: allLinearIndices[itemId], // 0부터 시작하는 순서
                };
            });

        // 서버에 업데이트 요청
        tagChangeOrder.mutate(updatedNodes, {
            onSuccess: (data, variables, context) => {
                setToast("success", "태그 순서 변경", "태그 순서가 변경되었습니다.");
            },
        });
    };

    useEffect(() => {
        if (tree.current) {
            tree.current?.selectItems(props.viewState[props.treeId].selectedItems);
        }
    }, [props.viewState]);

    const isItemEmpty = dataProvider.data.items["root"]?.children?.length === 0;

    return (
        <>
            <RefTagWrap>
                <RefTagHeader>
                    <TitleText size={"subSmall"} fontWeight={"regular"} color={"var(--color-Grey2)"}>
                        {props.treeId === Constants.TAG_TREE_ID_SIDEMENU && "태그별 보기"}
                    </TitleText>
                    <RefTagBtnWrap>
                        <Tooltip message={"모두 접기"}>
                            <Button
                                onlyIcon
                                transparentDisabled
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => {
                                    tree.current?.collapseAll();
                                }}
                                disabled={isItemEmpty}
                            >
                                <Icon
                                    name={"foldAll"}
                                    size={"14"}
                                    color={isItemEmpty ? "var(--color-DisabledButton)" : "var(--color-SubBlack)"}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip message={"모두 펼치기"}>
                            <Button
                                onlyIcon
                                transparentDisabled
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => {
                                    tree.current?.expandAll();
                                }}
                                disabled={isItemEmpty}
                            >
                                <Icon
                                    name={"openAll"}
                                    size={"14"}
                                    color={isItemEmpty ? "var(--color-DisabledButton)" : "var(--color-SubBlack)"}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip message={"추가"}>
                            <Button
                                onlyIcon
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => {
                                    injectItem();
                                }}
                            >
                                <Icon name={"plus"} size={"14"} color={"var(--color-SubBlack)"} />
                            </Button>
                        </Tooltip>
                        <Tooltip message={"삭제"}>
                            <Button
                                onlyIcon
                                transparentDisabled
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => {
                                    removeItem();
                                }}
                                disabled={isItemEmpty}
                            >
                                <Icon
                                    name={"minus"}
                                    size={"14"}
                                    color={isItemEmpty ? "var(--color-DisabledButton)" : "var(--color-SubBlack)"}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip message={"모두 선택 해제"} position={"left"}>
                            <Button
                                onlyIcon
                                transparentDisabled
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => {
                                    props.onSelectItems([]);
                                }}
                                disabled={isItemEmpty}
                            >
                                <Icon
                                    name={"refresh"}
                                    size={"14"}
                                    color={isItemEmpty ? "var(--color-DisabledButton)" : "var(--color-SubBlack)"}
                                />
                            </Button>
                        </Tooltip>
                    </RefTagBtnWrap>
                </RefTagHeader>
                <RefTagBody>
                    <UncontrolledTreeEnvironment
                        dataProvider={dataProvider}
                        getItemTitle={(item) => item.name}
                        viewState={props.viewState}
                        canDragAndDrop={props.treeId === Constants.TAG_TREE_ID_SIDEMENU}
                        canReorderItems={props.treeId === Constants.TAG_TREE_ID_SIDEMENU}
                        canDropOnFolder={props.treeId === Constants.TAG_TREE_ID_SIDEMENU}
                        canDropOnNonFolder={props.treeId === Constants.TAG_TREE_ID_SIDEMENU}
                        canSearch={false}
                        defaultInteractionMode={{
                            mode: "custom",
                            createInteractiveElementProps: (item, treeId, actions, renderFlags) => ({
                                onClick: (e) => {
                                    actions.focusItem();
                                    if (e.shiftKey) {
                                        let overrideOldSelection = true;
                                        actions.selectUpTo(overrideOldSelection);
                                    } else {
                                        if (renderFlags.isSelected) {
                                            actions.unselectItem();
                                        } else {
                                            actions.addToSelectedItems();
                                        }
                                    }
                                },
                                onFocus: () => {
                                    actions.focusItem();
                                },
                                onDragStart: (e) => {
                                    isTreeItemDragging = true;
                                    e.dataTransfer.dropEffect = "move";
                                    actions.startDragging();
                                },
                                onDragOver: (e) => {
                                    e.preventDefault(); // Allow drop
                                },
                                draggable: renderFlags.canDrag && !renderFlags.isRenaming,
                                tabIndex: !renderFlags.isRenaming ? (renderFlags.isFocused ? 0 : -1) : undefined,
                            }),
                        }}
                        keyboardBindings={{
                            // replace custom keybindings
                            renameItem: ["ENTER", "f2"],
                        }}
                        onSelectItems={(selectedItems) => {
                            if (isTreeItemDragging === false) {
                                props.onSelectItems(selectedItems);
                            }
                        }}
                        onRenameItem={(item, newName) => {
                            if (item.name === newName) {
                                return;
                            }
                            tagUpdate.mutate(
                                {
                                    id: item.index,
                                    name: newName,
                                },
                                {
                                    onSuccess: (data, variables, context) => {
                                        setToast("태그 이름이 변경되었습니다.", "success");
                                    },
                                    onError: (error) => {
                                        if (error.response.status === 400) {
                                            setToast(error.response.data.name, "warn");
                                        } else {
                                            setToast("태그 이름 변경에 실패했습니다.", "warn");
                                        }
                                        dataProvider.data.items[item.index].name = item.name;
                                        tree.current?.startRenamingItem(item.index);
                                    },
                                },
                            );
                        }}
                        onDrop={handleDrop}
                        renderItem={({ item, info, title, arrow, depth, context, children }) => (
                            <TreeItemContainer {...context.itemContainerWithChildrenProps} depth={Number(depth)}>
                                <TreeItemMain
                                    {...context.itemContainerWithoutChildrenProps}
                                    {...context.interactiveElementProps}
                                    isDraggingOver={context.isDraggingOver}
                                >
                                    {item.isFolder ? (
                                        <TreeItemArrow
                                            {...context.arrowProps}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (context.isExpanded) {
                                                    tree.current?.collapseItem(item.index);
                                                } else {
                                                    tree.current?.expandItem(item.index);
                                                }
                                            }}
                                        >
                                            <IconWrap>
                                                <Icon
                                                    name={context.isExpanded ? "folderDownward" : "folderRightward"}
                                                    size={"10px"}
                                                />
                                            </IconWrap>
                                        </TreeItemArrow>
                                    ) : (
                                        <BlankBox />
                                    )}

                                    <TreeItemTitle treeId={props.treeId}>
                                        <IconBox>
                                            {context.isSelected ? (
                                                <Icon name={"check"} size={"10px"} color={"var(--color-DarkKey)"} />
                                            ) : (
                                                <Icon
                                                    name={item.isFolder ? "folder" : "tag"}
                                                    size={"10px"}
                                                    color={"#9FA8AB"}
                                                />
                                            )}
                                        </IconBox>
                                        <GeneralText
                                            size={"regular"}
                                            fontWeight={context.isSelected ? "500" : "400"}
                                            color={context.isSelected ? "var(--color-Black)" : "var(--color-SubBlack)"}
                                            whiteSpace={"pre-wrap"}
                                        >
                                            {title}
                                        </GeneralText>
                                        {!context.isRenaming && (
                                            <GeneralText
                                                margin={"0 0 0 2px"}
                                                size={"small"}
                                                fontWeight={"500"}
                                                color={"var(--color-Grey1)"}
                                            >
                                                &nbsp;{item.citation_count}
                                            </GeneralText>
                                        )}
                                    </TreeItemTitle>
                                    <TreeItemKnob
                                        className={`tree-knob-${item.index}`} // TODO: ContextMenu, MenuItem 에서 ColorPicker(?) 연관성 제거
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowContextMenu(item.index === showContextMenu ? false : item.index);
                                        }}
                                    >
                                        <IconWrap>
                                            <Icon name={"submenu"} />
                                        </IconWrap>
                                        {showContextMenu === item.index && (
                                            <ContextMenu
                                                show={true}
                                                translate={"-92px, 6px"}
                                                minWidth={"70px"}
                                                items={[
                                                    {
                                                        name: "이름 변경",
                                                        action: () => {
                                                            context.startRenamingItem();
                                                        },
                                                    },
                                                    {
                                                        name: "삭제",
                                                        action: () => {
                                                            removeItem([item.index]);
                                                        },
                                                    },
                                                ]}
                                                onClose={() => setShowContextMenu(false)}
                                                triggerClassName={`tree-knob-${item.index}`}
                                            />
                                        )}
                                    </TreeItemKnob>
                                </TreeItemMain>
                                <TreeItemChildren>{children}</TreeItemChildren>
                            </TreeItemContainer>
                        )}
                    >
                        <Tree treeId={props.treeId} rootItem="root" treeLabel="Tags" ref={tree} />
                    </UncontrolledTreeEnvironment>
                </RefTagBody>
            </RefTagWrap>
        </>
    );
}

export default ReferenceTagTreeView;

import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useIsFetching, useIsMutating } from "react-query";
import { useSearchParams } from "react-router-dom";

import styled, { css } from "styled-components";

import AiCursor from "components/atoms/AiCursor";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import SentenceText from "components/atoms/text/SentenceText";
import TitleText from "components/atoms/text/TitleText";

import SearchedPaperItem from "components/organisms/search/SearchedPaperItem";
import GuestUserLayout from "components/templates/GuestUserLayout";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useBibliographyCreate } from "hooks/queries/useBibliographies";
import { useDeletePaperSearchHistory, usePaperSearch, usePaperSearchHistory } from "hooks/queries/usePapers";
import { useUser } from "hooks/queries/useUser";

import { useDevice } from "utils/device";
import { wsUrl } from "utils/urls";

const WidthLimit = styled.div`
    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    height: ${(props) => props.height};
    max-width: 1080px;

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 80px;
            max-width: 1080px;
        `};
`;

const Header = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    height: 48px;
    max-height: 48px;
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Line);
`;

const PageTitle = styled.h1`
    position: relative;
    top: 2px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-Black);
`;

const Body = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 48px 0 0 0;
    width: 100%;
    max-height: calc(100vh - 104px);
    overflow: auto;
`;

const SearchInputWrap = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 16px 0;
    background-color: var(--color-White);
`;

const SearchMaxWidth = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    width: 100%;
    max-width: calc(100% - 220px);

    ${(props) =>
        props.device.isPC &&
        css`
            max-width: calc(100% - 280px);
        `};
`;

const RefPoint = styled.div`
    position: relative;
    flex: 1;
    min-width: calc(100% - 56px);
    height: 40px;
`;

const InputWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Line);
    transition: all 120ms ease-in;
    z-index: 1;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const RowWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
`;

const SearchInput = styled.input`
    padding: 12px 4px;
    width: calc(100% - 10px);
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--color-White);
    color: var(--color-Black);
    border: transparent;
    outline: none;

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

const SearchButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SearchHistoryList = styled.div`
    flex-direction: column;
    margin: 0 8px;
    padding: 8px 0 4px 0;
    width: calc(100% - 16px);
    background-color: var(--color-White);
    border-top: solid 1px var(--color-Grey3);
`;

const SearchHistoryItem = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-Black);
    border: transparent;
    background-color: var(--color-White);

    &:hover {
        > div {
            background-color: var(--color-ButtonHover4);
        }
    }
`;

const ItemInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    background-color: var(--color-White);
    border-radius: 4px;
    border: transparent;

    &:first-child {
        margin: 4px 0 0 0;
    }

    &:last-child {
        margin: 0 0 4px 0;
    }
`;

const InnerLeft = styled.div`
    display: flex;
    align-items: center;
`;

const SearchContentWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    width: 100%;
`;

const SummaryBox = styled.div`
    position: sticky;
    top: 24px;
    margin: 0 0 0 20px;
    padding: 12px;
    min-width: 200px;
    max-width: 200px;
    background-color: var(--color-Base1);
    border-radius: 4px;
    border: solid 1px var(--color-Outline);

    ${(props) =>
        props.device.isPC &&
        css`
            min-width: 260px;
            max-width: 260px;
        `};
`;

const SummaryWrap = styled.div`
    margin: 8px 0 0 0;
    font-size: 12px;
`;

const SummaryTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SummaryText = styled.span``;

const SummaryStopButton = styled.div`
    cursor: pointer;
`;

const TableWrap = styled.div`
    flex: 1;
`;

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    height: 40px;
    background-color: var(--color-BaseBlue);
    border-radius: 4px;
`;

const TableBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 210px);

    > div:last-child {
        padding-bottom: 160px;
    }
`;

const BlankView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
`;

function SearchPage(props) {
    const device = useDevice();

    const [searchParams] = useSearchParams();

    const [searchInput, setSearchInput] = useState(searchParams.get("q") || "");
    const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");

    const [user, setUser] = useState({});
    const [paperList, setPaperList] = useState([]);
    const [summaryText, setSummaryText] = useState("");
    const [isSummaryIng, setSummaryIng] = useState(false);

    const [displaySearchHistory, setDisplaySearchHistory] = useState("none");
    const [searchHistory, setSearchHistory] = useState(JSON.parse(localStorage.getItem("searchHistory")) || []);

    const userQuery = useUser();
    const paperSearchQuery = usePaperSearch({ "search-query": `${searchQuery}` });
    const paperCreateMutation = useBibliographyCreate();

    const paperSearchHistoryQuery = usePaperSearchHistory();
    const paperSearchHistoryDeleteMutation = useDeletePaperSearchHistory();

    const isLoggedIn = localStorage.getItem("token") ? true : false;
    const ContainerComponent = isLoggedIn ? LoginUserLayout : GuestUserLayout;

    const wsRef = useRef(null);
    const searchInputRef = useRef(null);

    const { setLoading } = useLoading();
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    // 초기화
    useEffect(() => {
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
            paperSearchHistoryQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (wsRef.current) {
            wsRef.current.close();
            setSummaryText("");
        }
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
        if (searchQuery) {
            let tempSearchHistory = searchHistory;
            if (searchHistory.includes(searchInput)) {
                // 검색어를 포함하고 있다면 제거
                tempSearchHistory = searchHistory.filter((item) => item !== searchInput);
            }
            tempSearchHistory.unshift(searchInput);
            if (tempSearchHistory.length > 10) {
                // 길이가 10개를 넘어가면 마지막 요소를 제거
                tempSearchHistory.pop();
            }
            setSearchHistory(tempSearchHistory);
        }
        paperSearchQuery.refetch();
    }, [searchQuery]);

    useEffect(() => {
        if (paperSearchHistoryQuery.data) {
            if (user.email) {
                setSearchHistory(paperSearchHistoryQuery.data);
            }
        }
    }, [paperSearchHistoryQuery.data, user]);

    useEffect(() => {
        if (!isLoggedIn) {
            localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
        }
    }, [searchHistory.length]);

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
        if (paperSearchQuery.data) {
            setPaperList(paperSearchQuery.data);
        }
    }, [userQuery.data, paperSearchQuery.data]);

    useEffect(() => {
        if (searchQuery !== "" && paperList.length > 0) {
            setSummaryText("");

            wsRef.current = new WebSocket(wsUrl("searchSummary"));
            wsRef.current.onopen = (evt) => {
                wsRef.current.send(
                    JSON.stringify({
                        action: "summary",
                        papers: paperList.slice(0, 5).map((paper) => {
                            return {
                                title: paper.title,
                                year: paper.year,
                                abstract: paper.abstract,
                            };
                        }),
                    }),
                );
            };

            wsRef.current.onmessage = (evt) => {
                const data = JSON.parse(evt.data);

                if (data.content === "<END>") {
                    wsRef.current.close();
                } else {
                    setSummaryIng(true);
                    setSummaryText((prev) => {
                        return prev + data.content;
                    });
                }
            };

            wsRef.current.onerror = (error) => {
                console.log(error);
                // Alert("warn", "에러", "요약문을 생성하지 못했습니다.");
            };
            wsRef.current.onclose = (event) => {
                setSummaryIng(false);
                if (event.code !== 1000) {
                    // 정상 종료가 아닌 경우
                    console.log(event);
                    Alert("warn", "에러", "요약문을 생성하지 못했습니다.");
                }
                wsRef.current = null;
            };
        }
    }, [paperList]);

    const stopSummary = () => {
        if (wsRef.current) {
            wsRef.current.send(
                JSON.stringify({
                    action: "stop",
                    papers: [],
                }),
            );
        }
    };

    return (
        <ContainerComponent>
            <HtmlHead title={"논문 DB 검색"} />
            {isLoggedIn && (
                <Header>
                    <WidthLimit device={device} justifyContent={"space-between"} alignItems={"center"} height={"48px"}>
                        <PageTitle>논문 DB 검색</PageTitle>
                    </WidthLimit>
                </Header>
            )}

            <Body>
                <WidthLimit device={device} flexDirection={"column"}>
                    <SearchInputWrap isLoggedIn={isLoggedIn}>
                        <SearchMaxWidth device={device}>
                            <RefPoint>
                                <InputWrap>
                                    <RowWrap>
                                        <SearchInput
                                            ref={searchInputRef}
                                            placeholder="검색어를 입력해주세요"
                                            value={searchInput}
                                            onChange={(e) => {
                                                // 수정금지: 랜딩페이지에서 전달되는 쿼리 처리를 위해 searchQuery 와 searchInput 모두 필요
                                                setSearchInput(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                                                    if (searchInput) {
                                                        setSearchQuery(searchInput);
                                                    }
                                                }
                                            }}
                                            onFocus={() => {
                                                setDisplaySearchHistory("flex");
                                            }}
                                            onBlur={() => {
                                                setDisplaySearchHistory("none");
                                            }}
                                        />
                                        <SearchButtonWrap>
                                            {searchInput && (
                                                <Tooltip message={"초기화"}>
                                                    <Button
                                                        onlyIcon
                                                        bgColor={"transparent"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        onClick={(e) => {
                                                            setSearchInput("");
                                                        }}
                                                    >
                                                        <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip message={"검색"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"transparent"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={(e) => setSearchQuery(searchInput)}
                                                >
                                                    <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                                </Button>
                                            </Tooltip>
                                        </SearchButtonWrap>
                                    </RowWrap>
                                    {searchHistory.length > 0 && (
                                        <SearchHistoryList style={{ display: displaySearchHistory }}>
                                            {searchHistory.map((item, index) => (
                                                <SearchHistoryItem
                                                    key={index}
                                                    onMouseDown={(e) => {
                                                        setSearchInput(item);
                                                        setSearchQuery(item);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <ItemInner>
                                                        <InnerLeft>
                                                            <Icon
                                                                name={"last"}
                                                                size={"14"}
                                                                color={"var(--color-SubBlack)"}
                                                            />
                                                            <GeneralText
                                                                size={"large"}
                                                                color={"var(--color-Black)"}
                                                                margin={"0 0 0 12px"}
                                                            >
                                                                {item}
                                                            </GeneralText>
                                                        </InnerLeft>
                                                        <Tooltip message={"삭제"}>
                                                            <Button
                                                                onlyIcon
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover5)"}
                                                                onMouseDown={(e) => {
                                                                    setSearchHistory(
                                                                        searchHistory.filter((_, i) => i !== index),
                                                                    );
                                                                    if (isLoggedIn) {
                                                                        paperSearchHistoryDeleteMutation.mutate({
                                                                            query: item,
                                                                        });
                                                                    } else {
                                                                        localStorage.setItem(
                                                                            "searchHistory",
                                                                            JSON.stringify(
                                                                                searchHistory.filter(
                                                                                    (_, i) => i !== index,
                                                                                ),
                                                                            ),
                                                                        );
                                                                    }
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <Icon
                                                                    name={"delete"}
                                                                    size={"12"}
                                                                    color={"var(--color-SubBlack)"}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ItemInner>
                                                </SearchHistoryItem>
                                            ))}
                                        </SearchHistoryList>
                                    )}
                                </InputWrap>
                            </RefPoint>
                        </SearchMaxWidth>
                    </SearchInputWrap>

                    <SearchContentWrap>
                        {/* 검색결과 테이블 */}
                        <TableWrap>
                            <TableHeader>
                                <TitleText size={"small"}>검색결과</TitleText>
                                <a href="https://scienceon.kisti.re.kr" target="_blank">
                                    <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                        Powered by ScienceON
                                    </GeneralText>
                                </a>
                            </TableHeader>
                            <TableBody>
                                {paperList && paperList.length > 0 ? (
                                    paperList.map((item, index) => (
                                        <SearchedPaperItem
                                            key={index}
                                            index={index + 1}
                                            data={item}
                                            user={user}
                                            mutation={paperCreateMutation}
                                        />
                                    ))
                                ) : (
                                    <BlankView>
                                        <GeneralText size={"large"}>검색 결과가 없습니다.</GeneralText>
                                    </BlankView>
                                )}
                            </TableBody>
                        </TableWrap>

                        {/* 검색결과 요약 사이드 */}
                        {summaryText && (
                            <SummaryBox device={device}>
                                <SummaryTitle>
                                    <TitleText withBarType size={"small"}>
                                        검색 결과 요약
                                    </TitleText>
                                    {isSummaryIng && (
                                        <SummaryStopButton
                                            onClick={(e) => {
                                                stopSummary();
                                            }}
                                        >
                                            작성 중지
                                        </SummaryStopButton>
                                    )}
                                </SummaryTitle>
                                <SummaryWrap>
                                    <SentenceText size={"small"} display={"inline-block"}>
                                        <SummaryText
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    summaryText + (isSummaryIng ? renderToString(<AiCursor />) : ""),
                                            }}
                                        />
                                    </SentenceText>
                                </SummaryWrap>
                            </SummaryBox>
                        )}
                    </SearchContentWrap>
                </WidthLimit>
            </Body>
        </ContainerComponent>
    );
}

export default SearchPage;

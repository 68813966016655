import React from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin: ${(props) => props.margin};
    user-select: none;
    cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
`;

// 체크박스 입력 영역 스타일
const CheckboxInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
`;

// 라벨 텍스트 스타일
const CheckboxLabel = styled.div`
    margin: 0 0 0 8px;
    font-size: ${(props) => getFontSize(props.size)};
    color: ${(props) => (props.disabled ? "var(--color-DisabledText)" : "var(--color-Black)")};
    user-select: text;
`;

// 체크박스 크기에 따른 폰트 크기 결정
const getFontSize = (size) => {
    switch (size) {
        case "16px":
            return "12px";
        case "24px":
            return "16px";
        default:
            return "14px";
    }
};

// 체크박스 크기 매핑
const sizeMap = {
    small: "16px",
    regular: "20px",
    large: "24px",
};

function Checkbox({
    id,
    label,
    size = "regular",
    margin,
    checked = false,
    indeterminate = false,
    disabled = false,
    onChange,
}) {
    const actualSize = sizeMap[size] || sizeMap.regular;

    // 체크박스 상태 변경 핸들러
    const handleChange = () => {
        if (!disabled && onChange) {
            onChange({ target: { id: id, checked: !checked } });
        }
    };

    // 체크박스 상태에 따른 아이콘 이름 결정
    const getIconName = () => {
        if (disabled) {
            return indeterminate
                ? "checkboxTriStateDisabled"
                : checked
                ? "checkboxActiveDisabled"
                : "checkboxDisactiveDisabled";
        }
        return indeterminate ? "checkboxTriState" : checked ? "checkboxActive" : "checkboxDisactive";
    };

    return (
        <CheckboxContainer margin={margin} disabled={disabled} onClick={handleChange}>
            <CheckboxInput size={actualSize} disabled={disabled}>
                <Icon name={getIconName()} size={actualSize} />
            </CheckboxInput>
            {label && (
                <CheckboxLabel size={actualSize} disabled={disabled}>
                    {label}
                </CheckboxLabel>
            )}
        </CheckboxContainer>
    );
}

export default Checkbox;

import React from "react";

import styled, { css } from "styled-components";

const Container = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.margin || "0px"};
    padding: ${(props) => props.padding || "0"};
    width: ${(props) => props.width || "100%"};
    min-width: ${(props) => props.width};
    height: ${(props) => props.height || "40px"};
    border: ${(props) => props.border || "transparent"};
    border-radius: ${(props) => props.borderRadius || "4px"};
    background-color: ${(props) => props.bgColor || "#609FC2"};
    transition: all 120ms ease-in;

    ${(props) =>
        props.onlyIcon &&
        css`
            min-width: ${(props) => props.width};
        `}

    &:hover {
        background-color: ${(props) => props.hoverBgColor || "var(--color-Key)"};
    }

    &:disabled {
        background-color: ${(props) => (props.transparentDisabled ? "transparent" : "var(--color-DisabledButton)")};

        p {
            color var(--color-White);
        }
    }
`;

const ButtonIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.iconMargin || "0 8px 0 0"};
    width: ${(props) => props.iconSize || "16px"};
    height: ${(props) => props.iconSize || "16px"};
`;

const Size = css`
    ${(props) =>
        props.size === "xsmall" &&
        css`
            font-size: 1rem;
            font-weight: 400;
        `}
    ${(props) =>
        props.size === "small" &&
        css`
            font-size: 1.2rem;
            font-weight: 400;
        `}

    ${(props) =>
        props.size === "regular" &&
        css`
            font-size: 1.3rem;
            font-weight: 400;
        `}
`;

const Text = styled.p`
    margin: ${(props) => props.textMargin || "0"};
    line-height: 1.4;
    text-align: ${(props) => props.textAlign || "left"};
    font-weight: ${(props) => props.fontWeight || "600"};
    color: ${(props) => props.fontColor || "var(--color-White)"};
    transition: all 80ms ease-in;

    ${Size}

    ${(props) =>
        props.hoverTextStyle &&
        `
        &:hover { ${props.hoverTextStyle} };
    `}
`;

function Button(props) {
    const commonProps = {
        onClick: props.onClick,
        onMouseDown: props.onMouseDown,
        margin: props.margin,
        padding: props.padding,
        width: props.width,
        height: props.height,
        border: props.border,
        borderRadius: props.borderRadius,
        bgColor: props.bgColor,
        hoverBgColor: props.hoverBgColor,
        disabled: props.disabled,
        type: "button",
        className: props.className || "",
        size: props.fontSize,
        iconSize: props.iconSize,
        onlyIcon: props.onlyIcon,
        transparentDisabled: props.transparentDisabled,
    };

    const textProps = {
        textMargin: props.textMargin,
        size: props.fontSize || "regular",
        fontWeight: props.fontWeight,
        textAlign: props.textAlign,
        fontColor: props.fontColor,
        hoverTextStyle: props.hoverTextStyle,
    };

    if (props.onlyIcon) {
        return (
            <Container
                {...commonProps}
                width={props.buttonSize || "24px"}
                height={props.buttonSize || "24px"}
                bgColor={props.bgColor || "var(--color-Button1)"}
                hoverBgColor={props.hoverBgColor || "var(--color-ButtonHover1)"}
            >
                <ButtonIconWrap iconMargin={"0"} iconSize={props.iconSize}>
                    {props.children}
                </ButtonIconWrap>
            </Container>
        );
    }

    if (props.onlyText) {
        return (
            <Container {...commonProps}>
                <Text {...textProps}>{props.buttonText}</Text>
            </Container>
        );
    }

    return (
        <Container {...commonProps}>
            <ButtonIconWrap iconSize={props.iconSize}>{props.children}</ButtonIconWrap>
            <Text {...textProps}>{props.buttonText}</Text>
        </Container>
    );
}

export default Button;

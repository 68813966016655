import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div`
    margin: 0 0 8px 0;
`;

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
    width: 180px;
    height: 20px;
`;

const ResearchItemWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 180px;
    height: 20px;
`;

const ResearchItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.margin || "2px"};
    padding: 2px 4px;
    max-width: 180px;
    height: 20px;
    outline: none;
    background-color: var(--color-Research);
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ResearchActive);
    }
`;

const MoreResearch = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    padding: 2px 4px;
    max-width: 180px;
    height: 20px;
    outline: none;
    background-color: var(--color-ResearchActive);
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
`;

const BlankBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 20px;
    background-color: var(--color-BaseBlue);
    border-radius: 4px;
`;

const MoreBox = styled.div`
    position: absolute;
    top: 24px;
    right: 0;
    display: ${({ active }) => (active ? "block" : "none")};
    width: 240px;
    max-width: 240px;
    min-height: 80px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 1;
`;

const MoreBoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 32px;
    border-bottom: solid 1px var(--color-Outline);
`;

const MoreContentWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 8px 8px 8px;
    max-width: 240px;
    max-height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
`;

function ReferenceResearchList(props) {
    const moreBoxRef = useRef();
    const navigate = useNavigate();

    const [showResearchBox, setShowResearchBox] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreBoxRef.current && !moreBoxRef.current.contains(event.target)) {
                setShowResearchBox(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Container>
            <ItemHeader>
                <TitleText withBarType size={"subSmall"}>
                    리서치
                </TitleText>
                <Tooltip message={"리서치에 연결"}>
                    <Button
                        onlyIcon
                        buttonSize={"20px"}
                        bgColor={"transparent"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        onClick={props.onModifyClick}
                    >
                        <Icon name={"modify"} color={"var(--color-SubBlack)"} />
                    </Button>
                </Tooltip>
            </ItemHeader>
            {props.bibliography.researches.length > 0 ? (
                <ResearchItemWrap>
                    <ResearchItem
                        margin={"0 4px 0 0"}
                        style={props.bibliography.researches.length > 1 ? { maxWidth: "140px" } : { maxWidth: "180px" }}
                        onClick={(e) => {
                            navigate(`/reference?filter=r:"${props.bibliography.researches[0].display_name}"`);
                        }}
                    >
                        <GeneralText ellipsis size={"xsmall"}>
                            {props.bibliography.researches[0].display_name}
                        </GeneralText>
                    </ResearchItem>
                    {props.bibliography.researches.length > 1 && (
                        <MoreResearch
                            onClick={(e) => {
                                moreBoxRef.current = e.currentTarget;
                                setShowResearchBox(!showResearchBox);
                            }}
                        >
                            <GeneralText ellipsis size={"xsmall"}>
                                +{props.bibliography.researches.length - 1}
                            </GeneralText>
                            <MoreBox active={showResearchBox}>
                                <MoreBoxHeader>
                                    <TitleText size={"subSmall"} color={"var(--color-SubBlack)"}>
                                        리서치 목록
                                    </TitleText>
                                    <Icon name={"x"} size={"8"} onClick={(e) => setShowResearchBox(!showResearchBox)} />
                                </MoreBoxHeader>
                                <MoreContentWrap>
                                    {props.bibliography.researches.map((research, index) => (
                                        <ResearchItem
                                            key={research + index}
                                            onClick={(e) => {
                                                navigate(`/research/${research.uuid}`);
                                            }}
                                        >
                                            <GeneralText ellipsis size={"xsmall"}>
                                                {research.display_name}
                                            </GeneralText>
                                        </ResearchItem>
                                    ))}
                                </MoreContentWrap>
                            </MoreBox>
                        </MoreResearch>
                    )}
                </ResearchItemWrap>
            ) : (
                <BlankBox>
                    <GeneralText size={"small"} color={"var(--color-Grey1)"}>
                        인용된 리서치가 없습니다.
                    </GeneralText>
                </BlankBox>
            )}
        </Container>
    );
}

export default ReferenceResearchList;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import SentenceText from "components/atoms/text/SentenceText";
import TitleText from "components/atoms/text/TitleText";

import ReferenceResearchList from "components/organisms/reference/ReferenceResearchList";
import ReferenceTagList from "components/organisms/reference/ReferenceTagList";
import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceCitationEditModal from "components/page/modal/ReferenceCitationEditModal";
import ReferenceTagEditModal from "components/page/modal/ReferenceTagEditModal";

import { useBibliographyUpdate } from "hooks/queries/useBibliographies";

import Constants from "utils/constants";
import { getDateFromString, getTimeFromString } from "utils/dateUtil";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 8px 0;
    width: 100%;
`;

const OptionSection = styled.div`
    margin: 0px 8px 0 0;
    min-width: 20px;
    max-width: 20px;
`;

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: calc(100% - 28px);
    height: 160px;
    max-height: 160px;
    border-radius: 4px;
    border: solid 1px var(--color-Line);
`;

const MainSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    width: calc(100% - 204px);
    max-width: calc(100% - 204px);
    border-right: solid 1px var(--color-Line);
`;

const ReferInfo = styled.div``;

const DisplayName = styled.div``;
const CitationText = styled.div``;
const AbstractText = styled.div``;

const LinkText = styled(Link)`
    color: var(--color-SubBlack);

    &:hover {
        color: var(--color-Sub);
        text-decoration: underline !important;
    }
`;

const DateSection = styled.div`
    display: flex;
    align-items: center;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;

    &:last-child {
        margin: 0;
    }
`;

const DivideLine = styled.div`
    margin: 0 8px;
    width: 1px;
    height: 8px;
    border-right: solid 1px var(--color-Line);
`;

const SubSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    width: 204px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const SubItemContainer = styled.div``;

function ReferencePaperItem(props) {
    const displayNameRef = useRef(null);
    const citationTextRef = useRef(null);
    const dateSectionRef = useRef(null);
    const [abstractLines, setAbstractLines] = useState(1);

    const [showTagEditModal, setShowTagEditModal] = useState(false);
    const [showCitationEditModal, setShowCitationEditModal] = useState(false);

    const updateMutation = useBibliographyUpdate();

    useEffect(() => {
        if (displayNameRef.current && citationTextRef.current && dateSectionRef.current) {
            const total_height = 160;
            const padding_height = 12 * 2; // Box 위 아래
            const margin_height = 4 + 4 + 8; // 표시이름, 서지정보 아래, 초록 아래
            const abstract_fontsize = 12 * 1.5; // size * line-height
            const abstract_height =
                total_height -
                (padding_height +
                    margin_height +
                    displayNameRef.current.offsetHeight +
                    citationTextRef.current.offsetHeight +
                    dateSectionRef.current.offsetHeight);
            const abstract_line = parseInt(abstract_height / abstract_fontsize);
            setAbstractLines(abstract_line);
        }
    }, [displayNameRef.current, citationTextRef.current, dateSectionRef.current]);

    {
        /* 북마크 */
    }
    const handleBookmarkClick = () => {
        updateMutation.mutate({
            id: props.bibliography.id,
            is_bookmarked: !props.bibliography.is_bookmarked,
        });
    };

    {
        /* 서브 뷰 영역 */
    }
    const abstractAreaView = () => {
        const current_filter = props.searchParams?.get("filter");

        let searchParams = [];
        if (current_filter !== null) {
            const matches = current_filter.matchAll(Constants.SEARCH_FILTER_REGEX);
            for (const match of matches) {
                let prefix = match[1];
                let keywords = match[2].trim();

                if (keywords.length > 0) {
                    if (keywords.startsWith('"') && keywords.endsWith('"')) {
                        keywords = keywords.substring(1, keywords.length - 1);
                    }

                    // 일반 키워드는 검색에 사용
                    if (prefix === undefined) {
                        searchParams.push(keywords.includes(" ") ? '"' + keywords + '"' : keywords);
                    }
                }
            }
        }
        searchParams = searchParams.join(" ").trim();

        let abstract = props.bibliography.bib_json.abstract;

        if (searchParams) {
            if (abstract && abstract.includes(searchParams)) {
                return textIncludeView(abstract, searchParams);
            } else if (props.bibliography.origin_content) {
                return (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: props.bibliography.origin_content,
                        }}
                    ></span>
                );
            } else if (abstract) {
                return abstract;
            } else {
                return null;
            }
        } else {
            if (abstract) {
                return abstract;
            } else {
                return null;
            }
        }
    };

    const textIncludeView = (text, searchValue) => {
        if (text.includes(searchValue)) {
            const textLength = text.length;
            const searchValueLength = searchValue.length;
            const searchValueIndex = text.indexOf(searchValue);
            const startIndex = searchValueIndex - 100 > 0 ? searchValueIndex - 100 : 0;
            const endIndex = searchValueIndex + 100 < textLength ? searchValueIndex + 100 : textLength;
            const textStart = startIndex > 0 ? "..." : "";
            const textEnd = endIndex < textLength ? "..." : "";
            const textInclude = text.substring(startIndex, endIndex);
            const textIncludeStart = textInclude.substring(0, searchValueIndex - startIndex);
            const textIncludeEnd = textInclude.substring(
                searchValueIndex - startIndex + searchValueLength,
                textInclude.length,
            );
            return (
                <>
                    {textStart}
                    {textIncludeStart}
                    <span style={{ color: "var(--color-Sub)" }}>{searchValue}</span>
                    {textIncludeEnd}
                    {textEnd}
                </>
            );
        }
    };

    return (
        <Container>
            <OptionSection>
                <Checkbox
                    margin="12px 0px 6px 0px"
                    onChange={props.handleCheckboxChange}
                    checked={props.checkedState.includes(props?.bibliography.id)}
                    size={"20px"}
                />
                <Tooltip message={"즐겨찾기"}>
                    <Button
                        onlyIcon
                        buttonSize={"20px"}
                        bgColor={"var(--color-White)"}
                        hoverBgColor={"none"}
                        iconSize={"12px"}
                        border={
                            props?.bibliography.is_bookmarked
                                ? "solid 2px var(--color-Key)"
                                : "solid 2px var(--color-DisabledButton)"
                        }
                        onClick={handleBookmarkClick}
                    >
                        <Icon
                            name={"bookmark"}
                            color={
                                props?.bibliography.is_bookmarked ? "var(--color-Key)" : "var(--color-DisabledButton)"
                            }
                        />
                    </Button>
                </Tooltip>
            </OptionSection>
            <Box>
                <MainSection>
                    <ReferInfo>
                        {/* 참고문헌 표시이름 */}
                        <DisplayName ref={displayNameRef}>
                            <TitleText size={"small"} margin={"0 0 4px 0"} fontWeight={"400"}>
                                <LinkText to={"/reference/" + props?.bibliography.uuid}>
                                    {props?.bibliography.display_name}
                                </LinkText>
                            </TitleText>
                        </DisplayName>
                        {/*서지정보*/}
                        <CitationText ref={citationTextRef}>
                            <SentenceText
                                ellipsis
                                size={"small"}
                                margin={"0 0 4px 0"}
                                width={"100%"}
                                color={"var(--color-Grey2)"}
                            >
                                {props?.bibliography.styled_text}
                            </SentenceText>
                        </CitationText>
                        {/* 초록 */}
                        <AbstractText>
                            <SentenceText
                                multiEllipsis
                                size={"small"}
                                margin={"0 0 8px 0"}
                                width={"100%"}
                                color={abstractAreaView() === null ? "var(--color-Grey2)" : "var(--color-Grey1)"}
                                webkitLineClamp={abstractLines}
                            >
                                {abstractAreaView() || "초록이 없습니다."}
                            </SentenceText>
                        </AbstractText>
                    </ReferInfo>
                    <DateSection ref={dateSectionRef}>
                        <DateWrapper>
                            <GeneralText size={"xsmall"} margin={"0 8px 0 0"} color={"var(--color-Grey1)"}>
                                추가
                            </GeneralText>
                            <GeneralText size={"xsmall"} margin={"0 4px 0 0"} color={"var(--color-Grey1)"}>
                                {getDateFromString(props?.bibliography.created_at)}
                            </GeneralText>
                            <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                {getTimeFromString(props?.bibliography.created_at)}
                            </GeneralText>
                        </DateWrapper>
                        <DivideLine />
                        <DateWrapper>
                            <GeneralText size={"xsmall"} margin={"0 8px 0 0"} color={"var(--color-Grey1)"}>
                                최근 활동
                            </GeneralText>
                            <GeneralText size={"xsmall"} margin={"0 4px 0 0"} color={"var(--color-Grey1)"}>
                                {getDateFromString(props?.bibliography.updated_at)}
                            </GeneralText>
                            <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                {getTimeFromString(props?.bibliography.updated_at)}
                            </GeneralText>
                        </DateWrapper>
                    </DateSection>
                </MainSection>
                <SubSection>
                    <Link
                        {...(props?.bibliography.file && {
                            to: "/reference/" + props?.bibliography.uuid + "/review/" + props?.bibliography.file_uuid,
                        })}
                    >
                        <Button
                            onlyText
                            height={"24px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            fontSize={"11px"}
                            fontColor={"var(--color-White)"}
                            size={"small"}
                            buttonText={"PDF 리뷰"}
                            disabled={!props?.bibliography.file}
                        />
                    </Link>
                    <SubItemContainer>
                        <ReferenceResearchList
                            onModifyClick={(e) => setShowCitationEditModal(!showCitationEditModal)}
                            bibliography={props?.bibliography}
                        />
                        <ReferenceTagList
                            onModifyClick={(e) => setShowTagEditModal(!showTagEditModal)}
                            bibliography={props?.bibliography}
                        />
                    </SubItemContainer>
                </SubSection>
            </Box>
            {showCitationEditModal && (
                <AbstractModal width={480} modalTitle="리서치 연결" exitModal={(e) => setShowCitationEditModal(false)}>
                    <ReferenceCitationEditModal
                        exitModal={(e) => setShowCitationEditModal(false)}
                        citedResearches={props.bibliography.researches}
                        referenceId={props.bibliography.id}
                    />
                </AbstractModal>
            )}
            {showTagEditModal && (
                <AbstractModal width={480} modalTitle={"태그 선택"} exitModal={(e) => setShowTagEditModal(false)}>
                    <ReferenceTagEditModal
                        exitModal={(e) => setShowTagEditModal(false)}
                        referenceId={props.bibliography.id}
                        referenceFile={props.bibliography.file}
                        selectedTagIds={props.bibliography?.tag_mappings.map((tag) => tag.id)}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferencePaperItem;

import React, { useEffect, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled, { css } from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import TabAccountView from "components/organisms/settings/TabAccountView";
import TabEnvironView from "components/organisms/settings/TabEnvironView";
import TabPaymentView from "components/organisms/settings/TabPaymentView";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useUser } from "hooks/queries/useUser";

import { useDevice } from "utils/device";

const WidthLimit = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    height: ${(props) => props.height};
    max-height: ${(props) => props.maxHeight};
    max-width: 1080px;

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 80px;
            max-width: 1080px;
        `};
`;

const Header = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    height: 48px;
    max-height: 48px;
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Line);
`;

const TabSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: calc(100% - 40px);
    height: 48px;
`;

const TabItem = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 48px;
    background-color: transparent;
    border: transparent;

    &:hover {
        h2 {
            color: var(--color-Key);
        }
    }

    ${(props) =>
        props.isActive &&
        `
        & > p {
            font-weight: 600;
        }
    `}
`;

const ActiveBar = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${(props) => (props.isActive ? "block" : "none")};
    width: 100%;
    height: 3px;
    background-color: var(--color-Key);
`;

const ContentsBody = styled.div`
    display: flex;
    justify-content: center;
    margin: 48px 0 0 0;
    width: 100%;
    min-width: 671px;
    height: calc(100% - 104px);
    overflow: auto;
`;

function SettingsPage(props) {
    const device = useDevice();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setLoading } = useLoading();
    const tab = searchParams.get("tab");

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    const [activeTab, setActiveTab] = useState("account");
    const TABS = [
        { id: "account", name: "계정", component: TabAccountView },
        { id: "environ", name: "환경", component: TabEnvironView },
        { id: "payment", name: "결제", component: TabPaymentView },
    ];

    const userQuery = useUser();

    useEffect(() => {
        if (tab) {
            setActiveTab(tab);
        }
    }, [tab]);

    // 초기화
    useEffect(() => {
        if (userQuery.data) {
            if (!userQuery.data.is_authenticated) {
                navigate("/");
                Alert("warn", "에러", "비정상적인 접근입니다.");
            }
        }
    }, [userQuery.data]);

    return (
        <LoginUserLayout>
            <HtmlHead title={"설정"} />
            <Header>
                <WidthLimit device={device} justifyContent={"space-between"} alignItems={"center"} height={"48px"}>
                    <TitleText size={"small"}>설정</TitleText>
                    <TabSection>
                        {TABS.map((tab) => (
                            <TabItem key={tab.id} onClick={() => setActiveTab(tab.id)} isActive={activeTab === tab.id}>
                                <GeneralText
                                    size={"regular"}
                                    color={activeTab === tab.id ? "var(--color-Key)" : "var(--color-Grey1)"}
                                >
                                    {tab.name}
                                </GeneralText>
                                <ActiveBar isActive={activeTab === tab.id} />
                            </TabItem>
                        ))}
                    </TabSection>
                </WidthLimit>
            </Header>
            <ContentsBody>
                <WidthLimit device={device} justifyContent={"center"} maxHeight={"calc(100vh - 104px)"}>
                    {/* 계정 탭 뷰 */}
                    <TabAccountView isActive={activeTab === "account"} />
                    {/* 환경 탭 뷰 */}
                    <TabEnvironView isActive={activeTab === "environ"} />
                    {/* 결제 탭 뷰 */}
                    <TabPaymentView isActive={activeTab === "payment"} usingCapacity={props.usingCapacity} unit="MB" />
                </WidthLimit>
            </ContentsBody>
        </LoginUserLayout>
    );
}

export default SettingsPage;
